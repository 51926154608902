<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import { onMount } from 'svelte';
  import { readable } from 'svelte/store';

  import AltPollTeaser from '$components/amedia/alt-poll-teaser/component/alt-poll-teaser.component.svelte';
  import Filter from '$components/common/filter/filter.svelte';
  import InfoBox from '$components/style/info-box/info-box.svelte';
  import ModalDialog from '$components/style/modal/modal-dialog.svelte';
  import PublicationRecommenderWrapper from '$components/common/site-recommender/site-recommender.wrapper.svelte';
  import SitesRecommenderTeaserWrapper from '$components/common/site-recommender-teaser/site-recommender-teaser.wrapper.svelte';
  import SitesSelector from '$components/common/sites-selector/sites-selector.svelte';
  import SubscriptionsWidget from '$components/common/widgets/subscriptions-widget.svelte';
  import Teaserlist from '$components/common/teaserlist/teaserlist.svelte';
  import i18n from '$i18n/i18n.js';
  import { customClickEvent } from '$utils/adplogger.js';
  import { savePublicationPreferences } from '$utils/queries.js';
  import { canUseMotion } from '$stores/can-use-motion';
  import { fetchFrontFeedList } from '$stores/teaser-store';
  import type { DomainKeys, SitesDomains } from '$stores/sites-store';
  import { dirtySelectedSites, user } from '$stores/user-store';
  import DiscoverTeaserWrapper from '$components/common/teaserlist/discover-teaser-wrapper.svelte';
  import { ALL } from '$utils/consts';
  import Button from '$components/style/button/button.svelte';

  export let uuid: string;
  export let trackingKey: string | null;
  export let primarySite: string;
  export let sitesDomains: SitesDomains;
  export let domainKeys: DomainKeys;
  export let innerWidth: number;
  export let dialogComponent: HTMLDialogElement;
  export let sortedOptions: [string, string][];
  export let filter: string;
  export let adType: 'netboard' | 'midtbanner' | 'unknown' = 'unknown';
  export let location: string;

  const debug =
    new URL(window.location.href).searchParams.get('debug') === 'true';

  const resumeId = window.sessionStorage.getItem('altResumeId');

  const options = {
    debug,
    resumeId,
  };

  let teasers: Teaserlist['teasers'];
  let filterScrollList: HTMLElement | null = null;

  const errorStyle = css({
    margin: '$x6 $x4 $x20',
  });

  const displayContentStyle = css({
    display: 'contents',
  });

  const changeSitesStyle = css({
    margin: 'calc(-1 * $x3) auto $x8',
    paddingInline: '1rem',
    '@media screen and (min-width: 768px)': {
      paddingInline: '2rem',
    },
  });

  const filterFeed = () => {
    console.log(`?filter=${filter}#${location}`);
    if (filter !== ALL) {
      teasers.setFilter(filter);
      window.history.replaceState(null, '', `?filter=${filter}#${location}`);
    } else {
      window.history.replaceState(null, '', `?#${location}`);
      teasers.resetFilter();
    }

    window.scrollTo({
      top: 0,
      behavior: $canUseMotion ? 'smooth' : 'auto',
    });
  };

  let teaserListPromise = fetchFrontFeedList({
    trackingKey,
    uuid,
    location: 'frontpage',
  });

  $: hasFilterOptions = sortedOptions.length > 1;

  $: {
    if ($dirtySelectedSites)
      teaserListPromise = fetchFrontFeedList({
        trackingKey,
        uuid,
        location: 'frontpage',
        forceRefresh: true,
      });
  }

  $: selectedSites =
    $user.data?.preferredSites.map(({ siteKey }) => siteKey) || [];

  $: showFilter = hasFilterOptions;

  const ratingIndex = 9;
  const pollIndex = 12;

  const getComponentByIndex = (index: number, innerWidth: number) => {
    switch (index) {
      case 3: {
        return DiscoverTeaserWrapper;
      }
      case 7: {
        return SitesRecommenderTeaserWrapper;
      }
      case ratingIndex: {
        return AltPollTeaser;
      }
      case pollIndex: {
        return AltPollTeaser;
      }
      case 12: {
        if (innerWidth < 768) return SubscriptionsWidget;
        break;
      }
    }
    // We need to type this as any else Typescript will complain about the missing $$props type in the markup below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  };

  const onUpdate = (ev: CustomEvent) => {
    handleSelectedSites(ev);
  };

  const handleSelectedSitesClose = () => {
    dialogComponent.close();
  };

  const handleSelectedSites = async (e: CustomEvent) => {
    const { siteKey, checked } = e.detail;

    const event = customClickEvent(
      'mypublications-update',
      JSON.stringify({
        siteKey,
        preferred: checked,
      })
    );
    e.target?.dispatchEvent(event);

    await savePublicationPreferences({
      siteKey,
      preferred: checked,
    });

    filter = ALL;
    if (filterScrollList) filterScrollList.scrollLeft = 0;
  };

  onMount(async () => {
    await teaserListPromise;
    const queryParams = new URLSearchParams(window.location.search);
    filter = queryParams.get('filter') || ALL;
  });

  $: siteSelectorOpen = false;
</script>

<ModalDialog
  bind:dialogComponent
  on:close={() => (siteSelectorOpen = false)}
  on:open={() => (siteSelectorOpen = true)}
>
  <SitesSelector
    visible={siteSelectorOpen}
    container={dialogComponent}
    on:close={handleSelectedSitesClose}
    on:update={handleSelectedSites}
  />
</ModalDialog>

<PublicationRecommenderWrapper
  {uuid}
  {primarySite}
  {selectedSites}
  on:update={(ev) => {
    handleSelectedSites(ev);
  }}
/>

{#if showFilter}
  <Filter
    {sortedOptions}
    {sitesDomains}
    {ALL}
    bind:filter
    bind:list={filterScrollList}
    on:filter={filterFeed}
  >
    <Button
      text={$i18n.t('frontpage.buttons.changeSites')}
      size="none"
      type="signal"
      clickLabel="filter-feed"
      clickValue="add-more"
      inlinePadding="$x4"
      blockPadding="$x2"
      id="add-more"
      on:click={() => {
        dialogComponent.showModal();
        siteSelectorOpen = true;
      }}
    />
  </Filter>
{:else}
  <div class={changeSitesStyle()}>
    <Button
      text={'Legg til aviser'}
      size="none"
      type="signal"
      clickLabel="filter-feed"
      clickValue="add-more"
      inlinePadding="$x4"
      blockPadding="$x2"
      id="add-more"
      fullWidth
      on:click={() => {
        dialogComponent.showModal();
        siteSelectorOpen = true;
      }}
    />
  </div>
{/if}

{#await teaserListPromise then list}
  {@const teaserList = readable(list.teasers)}
  <div
    itemscope
    itemtype="https://www.adplogger.no/json-schema/CustomElement"
    class={displayContentStyle()}
  >
    <meta
      itemprop="custom-element#data"
      content={JSON.stringify({ tracer_request_id: list.id })}
    />
    <meta itemprop="custom-element#name" content="ordino_front_feed" />
    <Teaserlist
      {teaserList}
      {options}
      {domainKeys}
      {adType}
      {primarySite}
      showNewSiteTeaser={true}
      bind:teasers
    >
      <svelte:fragment slot="after" let:index>
        <svelte:component
          this={getComponentByIndex(index, innerWidth)}
          {selectedSites}
          {primarySite}
          {uuid}
          on:update={onUpdate}
          parameters={{
            pollType: index === pollIndex ? 'poll' : 'rating',
            userData: {
              uuid,
              trackingKey,
            },
            options: options,
            domainKeys: domainKeys,
            primarySite: primarySite,
            handleSelectedSites: handleSelectedSites,
          }}
        />
      </svelte:fragment>
    </Teaserlist>
  </div>
{:catch error}
  <div class={errorStyle()}>
    <InfoBox title={$i18n.t('log.error.title')} hasLogo>
      <p slot="text">{error.message}</p>
    </InfoBox>
  </div>
{/await}
